.Card {
    max-width: 90%;
    margin-bottom:20px;
    display: inline-block;
  }

.Media {
    width:300px;
}

.Paper {
    width: 100px;
    height: 100px;
    margin:0 auto;
    display:table;
    position: absolute;
    left: 0;
    right:0;
    top: 50%; 
    border:0px solid;
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    transform:translateY(-50%);
}